html,
body {
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

/* NavBar */
nav.nav-bar {
  height: 4rem;
  background-color: #fff;
}

nav.nav-bar > .nav-bar-img {
  max-height: 1.8em;
}

/* FullBanner */

a,
a:link,
a:visited {
  color: #fff;
  text-decoration: none;
}


#full-banner > .row > a.link::after {
  z-index: 0;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
}

a.link:hover img.img-banner {
  filter: brightness(.8);
}

a.link img.img-banner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: brightness(0.3);
}

a.link h4.subtitle {
  color: #fff;
  font-size: .8rem;
  text-align: center;
  font-family: 'Novecento-Light';
}

a.link h1.title {
  color: #fff;
  font-size: 4rem;
  text-align: center;
  font-family: 'Novecento-Light';
}

a.link button.cta {
  color: #fff;
  margin: 0 1rem;
  font-size: .8rem;
  letter-spacing: 5px;
  padding: .5rem 2rem;
  border: 1px solid #fff;
  background-color: transparent;
  font-family: 'Novecento-Light';
}

a.link:hover button.cta {
  color: #000;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  #full-banner {
    height: 100vh;  
  }
  #full-banner > .row > a.link {
    min-height: 55vh; 
  }
}